import React from 'react';
import { Link } from 'react-router-dom';

import style from './footer.module.css'

function Footer() {
    return (
        <div>
            <div id="footer" className={style.footerTopBackground}><div className={style.footerTop}></div></div>
            <div className={style.wrapper}>
                <div className={style.grid}>
                    <div>
                        <h3 className={style.title}>Wasteflow</h3>
                        <Link to="/"><p className=" a1 mt-4">{"Landing Page"}</p></Link>
                        <a className=" a1" href="https://favn.com/">Favn</a>
                    </div>
                    <div className="right">
                        <h3 className={style.title}>Kontakt</h3>
                        <p className="p mt-2">Epost: contact@favn.com</p>
                        <p className="p mt-2">Adresse: Kjøpmannsgata 59,</p>
                        <p className="p mt-2">7011 Trondheim</p>
                    </div>
                </div>
                <p className="p my-8">Powered by Favn</p>
            </div>
        </div>
    )
}

export default Footer;