import React from 'react';
import {Link} from 'react-router-dom';

import style from './salesPoints.module.css'

import PeaceSVG from './peace.svg'
import NavigationSVG from './navigation.svg'
import DataSVG from './dataChart.svg'

function SalesPoints() {
    return(
        <div className={style.illustrations}>
                <div className={style.illustrationsWrapper}>
                    <img className={style.todoSVG} src={PeaceSVG}></img>
                    <h2 className={style.illustrationsText}>Lavere terskel for riktig hånderting av avfall</h2>
                    <p className="p w90 mt-6">
                        Søppelhåndtering gjort lettere og mer praktisk. Våre løsninger muliggjør enkel bestilling av søppelhenting eller containere på nett og mobil. 
                    </p>
                    {/*<Link to="/"><p className="w90 a1 mt-1">{"Read more  >"}</p></Link>*/}
                </div>
                <div className={style.illustrationsWrapper}>
                    <img className={style.todoSVG} src={NavigationSVG}></img>
                    <h2 className={style.illustrationsText}>Optimalisert transport drevet av AI</h2>
                    <p className="p w90 mt-6">
                        Automatisert håndtering av henting og andre bestillinger. Henteruter genereres basert på algoritmer optimalisert med tanke på tid, ressursbruk og utslipp. 
                    </p>
                
                </div>
                <div className={style.illustrationsWrapper}>
                    <img className={style.todoSVG} src={DataSVG}></img>
                    <h2 className={style.illustrationsText}>Møt fremtiden med data ved din side</h2>
                    <p className="p w90 mt-6">
                        Utnytt potensialet i dataen som allerede finnes i bedriften og data som samles inn fra våre løsninger til å forutse fremtidige trender og optimalisere eksisterende prosesser.
                    </p>
                </div>
            </div>
    )
}

export default SalesPoints