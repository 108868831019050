import React from 'react';
import {Link} from 'react-router-dom';

import style from './people.module.css';

import AlexanderIMG from './AlexanderCarlsen.png';
import SveinungIMG from './Sveinung.png';
import HeleneIMG from './Helene.jpg';
import BjornarIMG from './Bjornar.jpg';
import AuroraIMG from './Aurora.jpg';

function People() {
    const people = [
        {name: 'Alexander Carlsen', img: AlexanderIMG, title: 'Daglig leder'},
        {name: 'Bjørnar Østtveit', img: BjornarIMG, title: 'Teknisk ansvarlig'},
        {name: 'Helene Gjengedal', img: HeleneIMG, title: 'Forretningsutvikler'},
        {name: 'Sveinung Øverland', img: SveinungIMG, title: 'Utvikler'},
        {name: 'Aurora Kjevik', img: AuroraIMG, title: 'UX-designer'},
    ]

    return(
        <div className="mt-16">
            <h2 className="ma mb-4">Teamet</h2>
            <div className={style.grid}>
                {people.map(people => (
                    <div>
                        <img className={style.image} src={people.img}></img>
                        <p  className={style.name}>{people.name}</p>
                        <p  className={style.title}>{people.title}</p>
                    </div>
                ))}
                
            </div>
        </div>
    )
}

export default People;