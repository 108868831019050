import React from 'react';
import { Link } from 'react-router-dom';

import style from './landingPage.module.css'

import SalesPoints from './../../components/salesPoints/salesPoints'
import Footer from '../../components/footer/footer';
import People from '../../components/people/people';

import TruckIMG from './wastetruck.svg';

function LandingPage() {
    return (
        <div>
            <div className={style.navbar}>
                <div className={style.navbarWrapper}>
                    <h3 className="ml-8 my-3">Wasteflow</h3>
                    <div className={style.left}>
                        {/*<Link className={style.link} to="/"><p className={` ma ${style.linkText}`} >register</p></Link>
                        <button className="button">Demo</button>*/}
                    </div>
                </div>
            </div>
            <div >
                <div className={style.landingWrapper}>
                    <div className={style.landing}>
                        <div>
                            <h1 className={style.header}>Wasteflow - digitalisert avfallshåndtering</h1>
                            <p className="p mt-6">Vi arbeider med å digitalisere prossesser for håndtering og transport av avfall. Vårt mål er å effektivisere og kutte kostnader innen søppelbehandling med fremtidsrettet programvare. Wasteflow ble startet høsten 2020 og er en oppstartsbedrift med tilknyttning til NTNU og Favn.</p>

                        </div>
                        <div className={style.landingLeft}>
                            <img className={` ma ${style.truck} `} src={TruckIMG}></img>
                            <a href="#footer" className="ma"><button className={`a button mt-8 ${style.actionButton}`}>Kontakt oss</button></a>
                        </div>
                    </div>
                </div>
                <div className={style.landingBottom}></div>
            </div>
            <SalesPoints />
            <People />
            <Footer />

        </div>
    )
}

export default LandingPage;